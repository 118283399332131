<template>
  <header>
    <NuxtLink to="/">
      <Logo />
    </NuxtLink>
    <nav>
      <ul>
        <li class="skip-mobile">
          <NuxtLink to="/">Home</NuxtLink>
        </li>
        <li class="skip-mobile">
          <NuxtLink to="/about/explore-courses">Explore Courses</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/articles">Articles</NuxtLink>
        </li>
        <li v-if="!loggedIn">
          <NuxtLink to="?login">Login</NuxtLink>
        </li>
        <li v-if="!loggedIn">
          <NuxtLink to="?signup">Signup</NuxtLink>
        </li>
        <li v-else>
          <NuxtLink to="#" @click="client.auth.signOut()">Logout</NuxtLink>
        </li>
      </ul>
    </nav>
  </header>
</template>
<script lang="ts" setup>
const user = useSupabaseUser();
const client = useSupabaseClient();
const loggedIn = computed(() => !!user.value);
</script>
<style scoped>
header {
  /* Box properties */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--grid-2);
  margin: 0;
  max-width: 100vw;
  overflow: hidden;
}

header h1 {
  /* Box properties */
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

nav ul {
  /* Box properties */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  /* Background & borders */
  /* Typography */
  list-style: none;
  font-family: var(--title-family);
  font-weight: 600;
  font-size: var(--font-size-small);
  /* Animation */
  /* Other */
}

nav ul li {
  /* Box properties */
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

nav ul li a {
  /* Box properties */
  display: block;
  padding: 0.5rem 1rem;
  margin: 0rem 0rem 0rem 2rem;
  /* Background & borders */
  border-radius: var(--border-radius);
  /* Typography */
  color: var(--dark-gray);
  /* Animation */
  transition: var(--transition);
  /* Other */
}

nav ul li a:hover {
  /* Box properties */
  /* Background & borders */
  /* Typography */
  color: var(--brand);
  /* Animation */
  /* Other */
}

nav ul li a.button {
  /* Box properties */
  /* Background & borders */
  margin: 0;
  /* Typography */
  /* Animation */
  /* Other */
}

@media screen and (max-width: 800px) {
  .skip-mobile {
    display: none;
  }

  header {
    display: flex;
    gap: var(--grid);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--grid);
  }
}
</style>
